// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"
import "@fontsource/poppins"
import { removeDefaultValues } from "gatsby-plugin-sharp/plugin-options"
const theme = {
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  colors: {},
  styles: {
    global: {
      html: {
        scrollBehavior: "smooth",
      },
      body: {
        bg: "gray.100",
        color: "gray.700",
        overflow: "hidden"
      },
      a: {
        color: "orange.500",
        _hover: {
          textDecoration: "none",
        },
      },
      "*": {
        scrollbarWidth: "auto",
        scrollbarColor: "gray.200 gray.200",
      },

      "*::-webkit-scrollbar": {
        width: "2px",
      },

      "*::-webkit-scrollbar-track": {
        background: "white",
      },

      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "gray.200",
        borderRadius: "10px",
        borderWidth: "3px",
        borderColor: "gray.200",
        borderStyle: "solid",
      },
    },
  },
  components: {
    Creative: {
      parts: ["container", "title", "smartphone", "video", "tags", "tag", "heading"],
      baseStyle: {
        background: {
          height: "100%",
          width: "100%",
          objectFit: "cover",
          position: "absolute",
          margin: "0",
        },
        container: {
          position: "absolute",
          display: "grid",
          gridTemplateAreas: `
            "smartphone heading"
            "smartphone tags"
            "smartphone elements"
          `,
          gridRowGap: "1rem",
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'auto 1fr 3fr',
          justifyItems: "center",
          width: "56%",
          aspectRatio: "16 / 9",
        },
        
        smartphone: {
          gridArea: "smartphone",
          height: "100%",
          aspectRatio: "1 / 2",
          gridRowStart: "1",
          gridRowEnd: "4",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          padding: "12.5% 3% 11.5% 3%",
          zIndex: "2"
        },
        video: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
        heading: {
          gridArea: "heading",
          textAlign: "center",
          fontSize: "2.5rem",
          zIndex: "2"
        },
        tags: {
          gridArea: "tags",
          zIndex: "2"
        },
        tag: {
          borderRadius: "full",
          padding: "0.25rem 1.5rem",
          margin: "0 0.25rem 0 0.25rem",
        },

        elements: {
          position: "relative",
          width: "100%",
          height: "100%",
          gridRowStart: "2",
          gridRowEnd: "4",
          gridArea: "elements",
        },
        backImage: {
          position: "absolute",
          zIndex: "1"
        },
        frontImage: {
          position: "absolute",
          zIndex: "3"
        },
      },
      variants: {
        default: {},
        rightTop: {
          container: {
            gridTemplateAreas: `
            "heading smartphone"
            "tags smartphone"
            "elements smartphone"
          `,
          },
        },
        rightBottom: {
          container: {
            gridTemplateRows: ' 3fr 1fr auto',
            gridTemplateAreas: `
            "elements smartphone"
            "heading smartphone"
            "tags smartphone "
          `,
          alignItems: "end"
          },
        },
        leftBottom: {
          container: {
            gridTemplateRows: ' 3fr 1fr auto',
            gridTemplateAreas: `
            "smartphone elements "
            "smartphone heading"
            "smartphone tags"
          `,
          alignItems: "end"
          },
        },
      },
    },
  },
}

export default extendTheme(theme)

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agata-meble-index-js": () => import("./../../../src/pages/AgataMeble/index.js" /* webpackChunkName: "component---src-pages-agata-meble-index-js" */),
  "component---src-pages-altered-carbon-index-js": () => import("./../../../src/pages/AlteredCarbon/index.js" /* webpackChunkName: "component---src-pages-altered-carbon-index-js" */),
  "component---src-pages-bez-nonsensow-index-js": () => import("./../../../src/pages/BezNonsensow/index.js" /* webpackChunkName: "component---src-pages-bez-nonsensow-index-js" */),
  "component---src-pages-cera-ve-index-js": () => import("./../../../src/pages/CeraVe/index.js" /* webpackChunkName: "component---src-pages-cera-ve-index-js" */),
  "component---src-pages-fortuna-index-js": () => import("./../../../src/pages/Fortuna/index.js" /* webpackChunkName: "component---src-pages-fortuna-index-js" */),
  "component---src-pages-gang-slodziakow-index-js": () => import("./../../../src/pages/GangSlodziakow/index.js" /* webpackChunkName: "component---src-pages-gang-slodziakow-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jordan-taste-paste-index-js": () => import("./../../../src/pages/JordanTastePaste/index.js" /* webpackChunkName: "component---src-pages-jordan-taste-paste-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-play-station-index-js": () => import("./../../../src/pages/PlayStation/index.js" /* webpackChunkName: "component---src-pages-play-station-index-js" */),
  "component---src-pages-ptasie-mleczko-index-js": () => import("./../../../src/pages/PtasieMleczko/index.js" /* webpackChunkName: "component---src-pages-ptasie-mleczko-index-js" */),
  "component---src-pages-redds-mango-cytryna-index-js": () => import("./../../../src/pages/ReddsMangoCytryna/index.js" /* webpackChunkName: "component---src-pages-redds-mango-cytryna-index-js" */),
  "component---src-pages-tyskie-index-js": () => import("./../../../src/pages/Tyskie/index.js" /* webpackChunkName: "component---src-pages-tyskie-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-warka-index-js": () => import("./../../../src/pages/Warka/index.js" /* webpackChunkName: "component---src-pages-warka-index-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

